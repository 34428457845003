<template>
    <v-container dense>
        <v-row dense>
            <v-col dense cols="12" sm="6" md="6">
                <v-subheader dense>
                    <slot v-bind:item="item">
                        <!-- {{ item.product.productName }} -->
                    </slot>
                </v-subheader>
            </v-col>
            <v-col dense cols="12" sm="6" md="6">
                <v-text-field type="number" dense v-model.number="item.valuePerRateFactor" @input="update" prefix="$" suffix="/ Unit" >
                    <template v-slot:append-outer>
                        <v-btn title="add increment" dense icon @click="addIncrement"><v-icon>mdi-plus-circle</v-icon></v-btn>
                    </template>
                </v-text-field>
                <v-row v-for="(incItem, index) in valueIncrements" :key="index">
                    <v-col dense cols="12" sm="6">
                        <v-text-field type="number" dense v-model.number="incItem.unitCountTrigger" @input="update"
                                    prefix=">=" :suffix="incItem.unitCountTrigger > 1 ? 'units is' : 'unit is'" />
                    </v-col>
                    <v-col dense cols="12" sm="6">
                        <v-text-field type="number" dense v-model.number="incItem.unitValue" @input="update"
                                    prefix="$" suffix="/ Unit">
                        <template v-slot:append>
                            <v-btn title="remove increment" dense icon @click="removeIncrement(index)"><v-icon>mdi-delete</v-icon></v-btn>
                        </template>
                    </v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-divider />
    </v-container>    
</template>

<script>
export default {
    name: 'Price-Tier-Item',
    data: function() {
        return {
            valueIncrements: []
        }
    },
    props: {
        item: {
            type: Object,
            default: null
        }
    },
    mounted() {
            this.valueIncrements = [];

            for (var i = 0; i < this.item.valueIncrements.length; i++) {
                var inc = this.item.valueIncrements[i];
                this.valueIncrements.push({
                    unitCountTrigger: inc.unitCountTrigger,
                    unitValue: inc.unitValue,
                    id: inc.id,
                });
            }
        },
    computed: {
        maxIncrement() {
            var max = 0;
                for (var i = 0; i < this.valueIncrements.length; i++) {
                    if (this.valueIncrements[i].unitCountTrigger > max) {
                        max = this.valueIncrements[i].unitCountTrigger;
                    }
                }                
                return +max + +1;
        }
    },
    methods: {
            addIncrement() {
                this.valueIncrements.push({
                    unitCountTrigger: this.maxIncrement,
                    unitValue: 0
                });

                this.valueIncrements.sort(function (b, a) {
                    if (a.unitCountTrigger > b.unitCountTrigger) {
                        return -1;
                    }
                    if (b.unitCountTrigger > a.unitCountTrigger) {
                        return 1;
                    }
                    return 0;
                });

                this.update();
            },
            removeIncrement(index) {
                this.valueIncrements.splice(index, 1);             
                this.update();
            },
            update() {     

                for (var i = 0; i < this.item.valueIncrements; i++) {
                    var inc = this.item.valueIncrements[i];
                    if (!inc.unitCountTrigger || inc.unitCountTrigger.length === 0) {
                        inc.unitCountTrigger = 0;
                    }                    
                    if (!inc.unitValue || inc.unitCountTrigger.length === 0) {
                        inc.unitValue = 0;
                    }
                }

                if (!this.item.valuePerRateFactor) {
                    this.item.valuePerRateFactor = 0;
                }

                this.item.valueIncrements = this.valueIncrements;

                console.log('emitting');
                console.log(this.item);
                this.$emit('change', this.item);
            }
        }
}
</script>